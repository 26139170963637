<template>
  <div class="class-datav">
    <!-- 头部指引 -->
    <div class="class-head">
      <div class="class-head-title">{{ $route.query.videoTitle }}</div>
      <div class="class-head-icon">
        <span
          :class="isFullscreen ? 'full-botton' : 'min-botton'"
          @click.stop="clickFullscreen"
        ></span>
        <span class="cloce-botton" @click="bindCOde"></span>
      </div>
    </div>
    <!--  内容  -->
    <div class="class-content">
      <!-- 内容左边 -->
      <div class="class-content-left">
        <div class="class-tec">
          <div class="class-box">
            <Head title="上课教师"></Head>
            <div class="class-content-tec">
              <div class="class-tec-list">
                <HappyScroll color="rgb(93, 119, 164)" size="5" hide-horizontal>
                  <ul>
                    <li v-for="(item, index) in tecData" :key="index">
                      <img
                        :src="
                          item.photo != ''
                            ? constant.URL + item.photo
                            : require('@/assets/img/school/icon_tec_tx.png')
                        "
                        alt=""
                      />
                      <span>{{ item.name }}</span>
                    </li>
                  </ul>
                </HappyScroll>
              </div>
              <div class="class-bar">
                <h2 class="class-bar-title">上课进度</h2>
                <div class="class-bar-pop">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="16"
                    :percentage="maxWatchTime"
                    :color="'#5D77A4'"
                  ></el-progress>
                </div>
              </div>
              <div class="class-ine">
                <h2 class="class-ine-title">上课累计积分</h2>
                <span class="num">{{ classIntegral }}分</span>
              </div>
              <div class="class-ine">
                <h2 class="class-ine-title">当前积分序号</h2>
                <span class="num">第{{ schoolRankingNum }}名</span>
              </div>
              <div class="class-ine">
                <h2 class="class-ine-title">当前在线家长10人获得积分</h2>
                <span class="num">+50分</span>
              </div>
            </div>
          </div>
        </div>
        <div class="class-student">
          <div class="class-box">
            <Head title="上课幼儿">
              <span>{{ studentData.length }}人</span>
            </Head>
            <div class="student-content">
              <ul>
                <li v-for="(item, index) in atteStudentList" :key="index">
                  <div class="l-list">
                    <p
                      class="stu-img"
                      v-if="studentData.indexOf(String(item.id)) < 0"
                    >
                      <img :src="item.sex == '1' ? men : women" alt="" />
                    </p>
                    <p v-else>
                      <img :src="item.sex == '1' ? men1 : women1" alt="" />
                    </p>
                    <!-- <p v-if="item.fatherStatus !== 0" class="par-img">
                      <img
                        src="../../assets/img/datav/icon_football.png"
                        alt=""
                      />
                      <img
                        src="../../assets/img/datav/icon_football.png"
                        alt=""
                      />
                    </p> -->
                  </div>
                  <p class="stu-name">{{ item.name }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 内容右边 -->
      <div class="class-content-right">
        <div class="class-content-right-top">
          <div class="class-video">
            <videoPlayer :videoid.sync="unnormalUrl" v-if="unnormalUrl" />
          </div>
          <div class="class-ranking">
            <div class="ranking-content">
              <div class="ranking-content-head">
                <ul>
                  <li><span>活跃</span><span>序号</span></li>
                  <li><span>同城</span><span>园所</span></li>
                  <li><span>幼儿</span><span>人数</span></li>
                  <li><span>活力</span><span>积分</span></li>
                </ul>
              </div>
              <div class="ranking-content-list">
                <ul>
                  <li
                    v-for="(item, index) in (rankingData || '').slice(0, 10)"
                    :key="index"
                  >
                    <div class="list-head">
                      <p>{{ index + 1 }}</p>
                      <p>{{ item.schoolName }}</p>
                      <p class="num">{{ item.registerStudentCount }}</p>
                      <p class="jifen">{{ item.integralTotal }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="class-content-right-borrom">
          <div class="class-charity">
            <div class="class-box">
              <Head title="小小慈善"></Head>
              <div class="class-charity-content">
                <img src="../../assets/img/datav/data_null.png" alt="" />
                <p>敬请期待</p>
              </div>
            </div>
          </div>
          <div class="class-integral">
            <div class="class-box">
              <Head title="积分商城:当前可兑换"></Head>
              <div class="integral-content">
                <!-- <ul>
                  <li v-for="(item, index) in integralData" :key="index">
                    <img :src="item.icon" alt="图标" />
                    <p>{{ item.name }}</p>
                  </li>
                </ul> -->
                <img src="../../assets/img/datav/data_null.png" alt="" />
                <p>敬请期待</p>
              </div>
            </div>
          </div>
          <div class="class-yundong">
            <div class="class-box">
              <Head title="小小运动会"></Head>
              <div class="yundong-content">
                <img src="../../assets/img/datav/data_null.png" alt="" />
                <p>敬请期待</p>
              </div>
            </div>
          </div>
          <div class="class-zhibo">
            <div class="class-box">
              <Head title="直播播报">
                <!-- <div class="class-zhibo-btn" @click="bindType">
                  <img src="../../assets/img/datav/icon_zhibo_btn.png" alt="" />
                  切换
                </div> -->
              </Head>
              <div class="class-zhibo-congent">
                <!-- <div class="class-video-con" v-show="!isMedia">
                  <img :src="liveVideo" alt="" />
                </div>
                <div v-show="isMedia">
                  <video ref="mediaVideo" class="mediaVideo"></video>
                </div>
                <div class="class-video-txt">
                  <h2 class="class-video-txt-title"></h2>
                  <div class="class-video-txt-list">
                    <p>王老师:发言文案</p>
                  </div>
                </div> -->
                <img src="../../assets/img/datav/data_null.png" alt="" />
                <p>敬请期待</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
import screenfull from "screenfull";
import Head from "@/components/datav/class/head.vue";
//import videoPlayer from "@/components/video.vue";
import videoPlayer from "@/components/new-video.vue";
import media from "@/utils/media.js";
import { HappyScroll } from "vue-happy-scroll";
import "vue-happy-scroll/docs/happy-scroll.css";
export default {
  name: "Screenfull",
  data() {
    return {
      men: require("@/assets/img/datav/Icon_stud_boy.png"), // 男
      women: require("@/assets/img/datav/Icon_stud_girl.png"), // 女
      men1: require("@/assets/img/school/icon_stud_girl_higt.png"), // 男
      women1: require("@/assets/img/school/icon_stud_boy_higt.png"), // 女
      stu: [],
      isFullscreen: true,
      isVideoToggle: true, // 判断视频切换
      integralData: [
        {
          icon: require("@/assets/img/datav/icon_football.png"),
          name: "足球",
        },
        {
          icon: require("@/assets/img/datav/Icon_cloth.png"),
          name: "球衣",
        },
        {
          icon: require("@/assets/img/datav/Icon_Watch.png"),
          name: "手表",
        },
        {
          icon: require("@/assets/img/datav/Icon_Mb.png"),
          name: "秒表",
        },
        {
          icon: require("@/assets/img/datav/Icon_football_Gray.png"),
          name: "足球",
        },
        {
          icon: require("@/assets/img/datav/Icon_cloth_Gray.png"),
          name: "球衣",
        },
        {
          icon: require("@/assets/img/datav/Icon_Watch_Gray.png"),
          name: "手表",
        },
        {
          icon: require("@/assets/img/datav/Icon_Mb_Gray.png"),
          name: "秒表",
        },
      ],
      studentData: this.$route.query.studentIds.split(","), // 上课学生列表
      tecData: [], // 上课老师列表
      sportsVideo: "", // 小小运动会视频
      charityVideo: "", // 小小慈善
      liveVideo: "", // 直播报告
      rankingData: [], // 排行榜数据储存
      unnormalUrl: this.$route.query.unnormalUrl, // 默认课程
      videoTime: "", // 视频总时间
      playVideoTime: "", // 播放总时间
      classIntegral: "", // 上课积分
      maxWatchTime: "", // 上课进度条
      isMedia: false, //是否有摄像头
      atteStudentList: "", // 学生列表,
      schoolRankingNum: "", // 排行榜
    };
  },
  mounted() {
    this.init();
    console.log();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
      this.getRanking(); // 获取排行榜
      this.getVideoDefault(); // 获取小小慈善、小小运动会、直播播报默认视频地址
      this.findTeachersByIds(); // 查询老师
      this.findClassIfo(); // 查询积分和进度
      // this.getStudentParentStatus(this.$route.query.studentIds);
      window.onresize = () => {
        if (!this.checkFull) {
          this.isFullscreen = false;
        }
      };
      this.getList();
      // this.getMedia(); //判断是否有摄像头
    },
    // 获取当前班级学生
    // 老师账号获取教师/学生/视频列表
    getList() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        teacherId: this.$store.state.userInfo.teacherId,
        gradeId: this.$route.query.gradeId,
      };
      this.api.course.findEntryClass(data).then((res) => {
        console.log(res, "获取当前班级学生");
        this.atteStudentList = res.data.atteStudentList.map((item) => {
          return {
            ...item,
            isCheck: true,
          };
        });
      });
    },
    // 开启大屏
    clickFullscreen() {
      console.log(screenfull.isFullscreen);
      if (!screenfull.isEnabled) {
        // 此时全屏不可用
        this.$message.warning("此时全屏组件不可用");
        return;
      }
      this.isFullscreen = screenfull.isFullscreen;
      screenfull.toggle();
    },
    checkFull() {
      let isFull =
        document.fullscreenEnabled ||
        window.fullScreen ||
        document.webkitIsFullScreen ||
        document.msFullscreenEnabled;
      console.log(isFull);
      if (isFull == undefined) {
        isFull = false;
      }
      return isFull;
    },
    // 关闭大屏
    bindCOde() {
      this.$router.go(-1);
    },
    // 获取排行榜
    getRanking() {
      let cityList = this.$store.state.schoolInfo.atteSchool.city;
      let city = cityList.split("/");
      console.log(city);
      let data = {
        province: city[0],
        area: city[2],
        city: city[1],
        rankingType: 0,
        schoolId: this.$store.state.userInfo.schoolId,
      };

      this.api.school.findTopByTypeDatav(data).then((res) => {
        console.log(res, "111111111111111111111111");
        this.schoolRankingNum = res.data.schoolRankingNum;
        this.rankingData = res.data.rankingList.map((item) => {
          return {
            ...item,
          };
        });
      });
    },
    // 获取小小慈善、小小运动会、直播播报默认视频地址
    getVideoDefault() {
      this.api.school.getVideoDefault().then((res) => {
        console.log(res);
        this.charityVideo = res.data[0].coverImage;
        this.sportsVideo = res.data[1].coverImage;
        this.liveVideo = res.data[2].coverImage;
      });
    },

    // 上课学生状态列表查询 getStudentParentStatus
    getStudentParentStatus(studentIds) {
      let data = {
        studentIds: studentIds,
      };
      this.api.school.getStudentParentStatus(data).then((res) => {
        console.log(res, "上课学生状态列表查询");
        this.studentData = res.data;
      });
    },

    // 查询老师
    findTeachersByIds() {
      let data = {
        ids: this.$route.query.teacherId,
      };
      this.api.school.findTeachersByIds(data).then((res) => {
        console.log(res, "查询老师");
        this.tecData = res.data;
      });
    },

    // 查询课程视频进度和上课累计积分
    findClassIfo() {
      let data = {
        courseId: parseInt(this.$route.query.courseId),
        gradeId: parseInt(this.$route.query.gradeId),
        schoolId: parseInt(this.$store.state.userInfo.schoolId),
        semesterId: parseInt(this.$store.state.userInfo.semesterId),
      };
      this.api.school.findClassIfo(data).then((res) => {
        console.log(res, "查询课程视频进度和上课累计积分");
        this.classIntegral = res.data.classIntegral;
        this.maxWatchTime = res.data.classPer;
      });
    },

    // 切换视频
    bindType() {
      if (this.isVideoToggle) {
        this.api.school.getVideoDefault().then((res) => {
          console.log(res);
          this.$nextTick(() => {
            this.unnormalUrl = res.data[2].video;
          });
        });
        this.isVideoToggle = false;
      } else {
        this.unnormalUrl = this.$route.query.unnormalUrl;
        this.isVideoToggle = true;
      }
    },

    change() {
      this.isFullscreen = screenfull.isFullscreen;
    },

    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },
    getMedia() {
      let mediaVideo = this.$refs.mediaVideo;
      // let canvas = document.createElement('canvas');
      // let context = canvas.getContext('2d');
      console.log(mediaVideo, "mediaVideo");
      if (
        navigator.mediaDevices.getUserMedia ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia
      ) {
        //调用用户媒体设备, 访问摄像头
        media.getUserMedia(
          {
            video: {
              width: 9,
              height: 7,
            },
          },
          (stream) => {
            this.isMedia = true;
            media.success(mediaVideo, stream);
          },
          media.error
        );
      } else {
        alert("不支持访问用户媒体");
      }
      // document.getElementById('capture').addEventListener('click', function () {
      //       context.drawImage(mediaVideo, 0, 0, 480, 320);
      //       //canvas 转base64图片
      //       // var url = canvas.toDataURL('image/jpeg',1);
      //   })
    },
  },
  components: {
    videoPlayer,
    Head,
    HappyScroll,
  },
};
</script>
 
<style lang="scss" scoped>
.class-datav {
  width: 100%;
  height: 100%;
  background: url(../../assets/img/datav/class_datav_bg.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 0;
  left: 0;
}
.class-box {
  padding: 15px 21px;
}
.class-head {
  width: 100%;
  height: 77px;
  background: #3f528e;
  box-shadow: 0px 5px 10px 0px rgba(189, 189, 189, 0.21);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .class-head-title {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 65px;
  }
  .class-head-icon {
    padding-right: 65px;
    display: flex;
    span {
      display: block;
      margin-right: 31px;
    }
    .full-botton {
      width: 36px;
      height: 36px;
      background: url(../../assets/img/datav/icon_max.png) no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .min-botton {
      width: 36px;
      height: 36px;
      background: url(../../assets/img/datav/icon_min.png) no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .cloce-botton {
      width: 39px;
      height: 35px;
      background: url(../../assets/img/datav/icon_cloce.png) no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
.class-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.class-content-left {
  width: 395px;
  .class-tec {
    width: 100%;
    height: 400px;
    background: url(../../assets/img/datav/class_tec_bg.png) no-repeat;
    background-size: 100% 100%;
    .class-content-tec {
      margin-top: 20px;
      .class-tec-list {
        height: 110px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 50%;
            margin-bottom: 23px;
            img {
              width: 35px;
              height: 35px;
              border-radius: 35px;
            }
            span {
              font-size: 22px;
              color: #85a0d1;
              margin-left: 15px;
            }
          }
        }
      }
    }
    .class-bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .class-bar-title {
        font-size: 20px;
        color: #bbbbbb;
      }
      .class-bar-pop {
        width: 246px;
        height: 16px;
        border-radius: 8px;
        background: #040405;
        .class-bar-pup {
          width: 157px;
          height: 16px;
          background: #5d77a4;
          border-radius: 8px 8px 8px 8px;
        }
      }
    }
    .class-ine {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      color: #bbbbbb;
      margin-top: 18px;
      span {
        color: #ffa000;
      }
    }
  }
  .class-student {
    width: 100%;
    height: 593px;
    background: url(../../assets/img/datav/class_student_bg.png) no-repeat;
    background-size: 100% 100%;
    .student-content {
      margin-top: 27px;
      height: 480px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0.116667rem /* 14/120 */;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-track {
        background: #eeeeee;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5d77a4;

        border-radius: 0.05rem /* 6/120 */;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 25%;
          text-align: center;
          margin-bottom: 12px;
          .l-list {
            display: flex;
            width: 100%;
            justify-content: center;
          }
          .stu_img img {
            width: 27px;
            height: 40px;
          }
          .par-img img {
            width: 16px;
            height: 16px;
            border-radius: 16px;
            display: block;
            margin-bottom: 10px;
            margin-left: 10px;
          }
        }
      }
    }
    .stu-name {
      font-size: 16px;
      font-weight: 400;
      color: #777777;
    }
  }
}
.class-content-right {
  .class-content-right-top {
    display: flex;
    .class-video {
      width: 1120px;
      height: 666px;
    }
    .class-ranking {
      width: 400px;
      height: 666px;
      background: url(../../assets/img/datav/class_ranking_bg.png) no-repeat;
      background-size: 100% 100%;
      .ranking-content {
        .ranking-content-head {
          width: 100%;
          height: 93px;
          ul {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            li {
              flex: 1;
              text-align: center;
              span {
                font-size: 20px;
                font-weight: bold;
                color: #bbbbbb;
                display: block;
              }
            }
            li:nth-child(2) {
              flex: 2;
            }
          }
        }
        .ranking-content-list {
          ul {
            li {
              width: 100%;
              height: 57px;
              z-index: 999;
              .list-head {
                padding: 0 15px;
                display: flex;
                justify-content: space-between;
                text-align: center;
              }
              p {
                font-size: 16px;
                line-height: 57px;
                font-weight: 400;
                color: #bbbbbb;
                flex: 1;
              }
              p:nth-child(2) {
                flex: 2;
                // width:180px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .jifen {
                color: #fac000;
              }
              .num {
                color: #85a0d1;
              }
            }
            li:nth-child(odd) {
              background: #ffffff12;
            }
          }
        }
      }
    }
  }
  .class-content-right-borrom {
    display: flex;
    .class-charity {
      width: 350px;
      height: 327px;
      background: url(../../assets/img/datav/class_cs_bg.png) no-repeat;
      background-size: 100% 100%;
      .class-charity-content {
        width: 308px;
        height: 217px;
        border-radius: 6px;
        text-align: center;
        img {
          width: 83px;
          height: 95px;
          margin-top: 50px;
        }
        p {
          color: #6a81a5;
          font-size: 18px;
          margin-top: 15px;
        }
      }
    }
    .class-integral {
      width: 351px;
      height: 327px;
      background: url(../../assets/img/datav/class_jf_bg.png) no-repeat;
      background-size: 100% 100%;
      .integral-content {
        text-align: center;
        img {
          width: 83px;
          height: 95px;
          margin-top: 50px;
        }
        p {
          font-size: 18px;
          color: #6a81a5;
          margin-top: 15px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 25%;
            text-align: center;
            margin-top: 35px;
            p {
              font-size: 16px;
              font-weight: bold;
              color: #fac000;
              margin-top: 16px;
            }
          }
        }
      }
    }
    .class-yundong {
      width: 300px;
      height: 327px;
      background: url(../../assets/img/datav/class_yundong_bg.png) no-repeat;
      background-size: 100% 100%;
      .yundong-content {
        width: 261px;
        height: 217px;
        text-align: center;
        img {
          width: 83px;
          height: 95px;
          margin-top: 50px;
        }
        p {
          font-size: 18px;
          font-weight: 400;
          margin-top: 14px;
          color: #6a81a5;
        }
      }
    }
    .class-zhibo {
      width: 523px;
      height: 327px;
      background: url(../../assets/img/datav/class_zb_bg.png) no-repeat;
      background-size: 100% 100%;
      .class-zhibo-btn {
        width: 80px;
        height: 29px;
        background: #ffffff;
        opacity: 0.3;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 17px;
          height: 12px;
        }
      }
      .class-zhibo-congent {
        margin-top: 20px;
        text-align: center;
        img {
          width: 83px;
          height: 95px;
          margin-top: 50px;
        }
        p {
          font-size: 0.18rem;
          font-weight: 400;
          margin-top: 0.14rem;
          color: #6a81a5;
        }
        .class-video-con {
          width: 276px;
          height: 217px;
          background: #333333;
          box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.08);
          opacity: 0.4;
          border-radius: 6px;
          img {
            width: 276px;
            height: 217px;
          }
        }
        .mediaVideo {
          // margin:0 auto;
          width: 276px;
          height: 217px;
        }
        .class-video-txt {
          margin-left: 17px;
          .class-video-txt-title {
            font-size: 20px;
            font-weight: 400;
            color: #dadada;
            margin-bottom: 20px;
          }
          .class-video-txt-list {
            p {
              font-size: 16px;
              font-weight: 400;
              color: #bbbbbb;
              margin-bottom: 11px;
            }
          }
        }
      }
    }
  }
}
/deep/ .happy-scroll-content {
  width: 100%;
}
</style>