<template>
  <div class="my_video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="options"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
    ></video-player>
  </div>
</template>

<script>
export default {
  name: "my_video",
  props: {
    videoid: {
      //视频id
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      gameId: "", // 游戏ID
      gameList: [], // 游戏列表
      fullVideoURL: "", // 视频默认图片
      timerEnd: "",
      isChallenge: false, // 判断挑战视频
      alertShow: false,
      onlineLearnedTime: 0, // 播放时间
      duration: "", // 默认video视频
      options: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: true, // 是否自动播放
        muted: true, // 是否静音
        loop: true, // 是否开启循环播放
        preload: "auto", // 自动预加载
        language: "zh-CN", // 语言，'en', 'zh-cn', 'zh-tw'
        aspectRatio: "1120:666", // 播放器高宽占比（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 是否显示当前时间和持续时间的分隔符，"/"
          durationDisplay: false, // 是否显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间
          fullscreenToggle: false, // 是否显示全屏按钮
        },
      },
    };
  },
  async mounted() {
    //如果为体验课 去掉是否购课校验 判断视频是否获取成功
    await this.getSource1();
    console.log(this.videoid, "我的ID 找我来啊");
  },

  methods: {
    getSource1() {
      this.api.zhSchool.getGameList(this.videoid).then((res) => {
        this.options.sources[0].src = res.data.playInfoList[0].playURL;
      });
    },
    //  播放
    onPlayerPlay() {},
    // 暂停回调
    onPlayerPause() {},
    // 视频播完回调
    onPlayerEnded() {},
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting() {},
    // 已开始播放回调
    onPlayerPlaying() {},
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata() {},
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate() {
      //console.log(this.onlineLearnedTime);
      //console.log($event, "4444444444444444");
    },
    // 媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay() {},

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough() {},
    //播放状态改变回调
    playerStateChanged() {},
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied() {},
  },
  watch: {
    // videoid: {
    //   handler(val) {
    //     console.log(val);
    //     this.videoid = val;
    //   },
    //   deep: true, //true 深度监听
    // },
  },
};
</script>

<style scoped lang="scss">
.my_video {
  width: 100%;
  height: 100%;
  position: relative;
}
/deep/ .video-player {
  width: 100%;
  height: 100%;
}
.alert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  @include flex();
  .pop-common {
    border-radius: 0.15rem !important;
    border: solid 10px #cc1a30;
    width: 18%;
    background: #fff;
    position: relative;
  }

  .sharePop .url {
    color: #cc1a30;
    font-size: 0.24rem;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 0.2rem;
  }

  .pop-common .layui-layer-content {
    padding: 0.4rem 0.45rem;
    font-size: 0.2rem;
    line-height: 0.35rem;
  }

  .sharePop {
    text-align: center;
  }

  .sharePop .img {
    margin-bottom: 0.2rem;
  }

  .sharePop .act {
    height: 0.1rem;
    line-height: 0.1rem;
    background: #cc1a30;
    color: #fff;
    display: inline-block;
    font-size: 0.24rem;
    font-weight: bold;
    padding: 0.2rem 0.4rem;
    margin: 0px 0.2rem;
    cursor: pointer;
  }

  .layui-layer-close2 {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 36px;
    height: 32px;
    margin-left: 0;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    // background-position: -146px -32px; /*no*/
    @include bg("../assets/img/close.png");
  }
  /* 弹窗 */
  .pop-promt.layui-layer {
    border: solid 0.1rem #cb1b31;
    width: 6.8rem !important;
    height: 5.6rem !important;
    @include trans-center();
  }

  .pop-promt .promt {
    padding-top: 2.5rem;
    text-align: center;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn0 {
    background: #f5d1d6 !important;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn1 {
    background: #cb1b31 !important;
  }

  .pop-promt .layui-layer-content {
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #cb1b31;
    overflow: hidden;
  }

  .pop-promt .sucess {
    background: url(../assets/img/sucess.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .warn {
    background: url(../assets/img/warn.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .fail {
    background: url("../assets/img/fail.png") center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .layui-layer-btn {
    text-align: center;
    display: flex;
    padding: 0px 0.7rem 0.4rem 0.7rem;
    position: absolute;
    width: 100%;
    bottom: 0.4rem;
    box-sizing: border-box;
  }

  .pop-promt .layui-layer-btn a {
    height: 0.7rem;
    font-size: 0.28rem;
    color: #fff;
    border: none;
    line-height: 0.7rem;
    border-radius: 0.5rem;
    flex: 1;
    margin: 0 15px;
    text-align: center;
    font-weight: 400;
    padding: 0;
  }

  .promt .tips {
    font-size: 0.32rem;
    line-height: 0.4rem;
  }

  .promt .desc {
    font-size: 0.24rem;
    line-height: 0.4rem;
  }

  .layui-layer {
    font-size: 0.18rem;
  }

  .iframe {
  }

  .iframe html {
    font-size: 100px;
  }

  .layui-layer-resize {
    display: none;
  }
}
.challenge {
  width: 1120px;
  height: 666px;
  background: #00000050;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .challenge-content {
    width: 831px;
    height: 563px;
    background: url(../assets/img/zhSchool/popup/Pic_Bg_Tc_GSTZ.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .close_btn {
      position: absolute;
      top: 38px;
      right: 18px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .challenge-content-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      padding-top: 16px;
    }
    .challenge-content-list {
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .challenge-content-item {
        text-align: center;
        .challenge-content-item-img-active {
          width: 215px;
          height: 134px;
          background: url(../assets/img/zhSchool/popup/Pic_GSTZ_BK_C.png)
            no-repeat !important;
          background-size: 100% 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .challenge-content-item-img {
          width: 215px;
          height: 134px;
          background: url(../assets/img/zhSchool/popup/Pic_GSTZ_BK_U.png)
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          video {
            width: 192px;
            height: 110px;
            opacity: 0.4;
            border-radius: 6px;
          }
          video::-webkit-media-controls {
            display: none !important;
          }
          img {
            width: 48px;
            height: 46px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .challenge-content-item-name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }
  }
  .challenge-content-rule {
    padding: 0 60px;
    margin-top: 40px;
    ul {
      li {
        display: flex;
        .bt {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c3e8ff;
          line-height: 28px;
          opacity: 0.6;
        }
        .num {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c3e8ff;
          line-height: 28px;
          flex: 1;
          margin-left: 10px;
        }
      }
    }
  }
  .challenge-content-btn {
    display: flex;
    justify-content: center;
    margin-top: 13px;
    .challenge-content-btn-que {
      width: 160px;
      height: 66px;
      background: url(../assets/img/zhSchool/popup/Btn_Bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 66px;
      cursor: pointer;
    }
    .challenge-content-btn-cance {
      width: 160px;
      height: 66px;
      background: url(../assets/img/zhSchool/popup/Btn_Bg_canne.png) no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 66px;
      cursor: pointer;
    }
  }
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
// /deep/ .vjs-custom-skin > .video-js .vjs-big-play-button {
//   display: none !important;
// }
/deep/ .vjs-big-play-button {
  display: none !important;
}
/deep/ .vjs-control-bar {
  display: none !important;
}
/deep/ .video-js {
  width: 215px !important;
  height: 134px !important;
  padding-top: 0 !important;
  background: none !important;
  margin-top: 8px !important;
}
/deep/ .video-js .vjs-tech {
  width: 192px;
  height: 110px;
  border-radius: 6px;
  position: static;
}
</style>