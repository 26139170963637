<template>
  <div class="my_video">
    <video-player
      class="video-player vjs-custom-skin"
      ref="videoPlayer"
      :playsinline="true"
      :options="options"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @ended="onPlayerEnded($event)"
      @waiting="onPlayerWaiting($event)"
      @playing="onPlayerPlaying($event)"
      @loadeddata="onPlayerLoadeddata($event)"
      @timeupdate="onPlayerTimeupdate($event)"
      @canplay="onPlayerCanplay($event)"
      @canplaythrough="onPlayerCanplaythrough($event)"
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
    ></video-player>
    <div class="alert" v-if="alertShow">
      <div class="layui-layer layui-layer-page pop-sign pop-promt">
        <a
          class="layui-layer-ico layui-layer-close layui-layer-close2"
          @click="alertShow = false"
          href="javascript:;"
        ></a>
        <div id="" class="layui-layer-content">
          <div class="promt sucess">
            <div class="tips">立即给幼儿评分？</div>
          </div>
        </div>
        <div class="layui-layer-btn layui-layer-btn-">
          <a class="layui-layer-btn1" @click="toEvaluate">确定</a
          ><a class="layui-layer-btn0" @click="alertShow = false">取消</a>
        </div>
      </div>
    </div>
    <div class="challenge" v-if="isChallenge">
      <div class="challenge-content">
        <div class="close_btn" @click="bindClose">
          <img src="../assets/img/zhSchool/popup/Icon_Tc_Close.png" alt="" />
        </div>
        <div class="challenge-content-title">怪兽挑战</div>
        <div class="challenge-content-list">
          <div
            class="challenge-content-item"
            v-for="(item, index) in gameList"
            :key="index"
            @click="bindSelectActive(item.id)"
          >
            <div
              class="challenge-content-item-img"
              :class="
                item.id == gameId ? 'challenge-content-item-img-active' : ''
              "
            >
              <!-- <video
                controls="controls"
                autoplay
                loop
                muted
                :src="item.videoUrl"
              ></video> -->
              <videos :videoid="item.videoUrl" />
              <img
                src="../assets/img/zhSchool/popup/Icon_Choose.png"
                alt=""
                v-if="item.id == gameId"
              />
            </div>
            <h2 class="challenge-content-item-name">{{ item.name }}</h2>
          </div>
        </div>
        <div class="challenge-content-rule">
          <ul>
            <li>
              <p class="bt">挑战人数:</p>
              <p class="num">5名幼儿参加</p>
            </li>
            <li>
              <p class="bt">动作要求:</p>
              <p class="num">
                需要在开阔场地运动，幼儿站成一排，有一定的间隔，避免动作重叠和遮挡摄像头，误伤
                别人。
              </p>
            </li>
            <!-- <li>
              <p class="bt">动作标准:</p>
              <p class="num">
                参加挑战的幼儿向前伸出双手，下蹲，然后向上跳起，完成一个动作
              </p>
            </li> -->
            <li>
              <p class="bt">得分标准:</p>
              <p class="num">
                在规定时间内幼儿做的次数越多，统计全部幼儿的次数，得分越高。
              </p>
            </li>
            <li>
              <p class="bt">时间限定:</p>
              <p class="num">分钟</p>
            </li>
          </ul>
        </div>
        <div class="challenge-content-btn">
          <div class="challenge-content-btn-que" @click="bindToRoute">
            接受挑战
          </div>
          <div class="challenge-content-btn-cance" @click="bindClose">
            拒绝挑战
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import videos from "./zhGameVideo.vue";
export default {
  name: "my_video",
  props: {
    videoid: {
      //视频id
      type: String,
      default: () => null,
    },
  },
  components: {
    videos,
  },
  data() {
    return {
      gameId: "", // 游戏ID
      gameList: [], // 游戏列表
      fullVideoURL: "", // 视频默认图片
      timerEnd: "",
      isChallenge: false, // 判断挑战视频
      alertShow: false,
      onlineLearnedTime: 0, // 播放时间
      duration: "", // 默认video视频
      options: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 是否自动播放
        muted: false, // 是否静音
        loop: false, // 是否开启循环播放
        preload: "auto", // 自动预加载
        language: "zh-CN", // 语言，'en', 'zh-cn', 'zh-tw'
        aspectRatio: "1120:666", // 播放器高宽占比（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "application/x-mpegURL", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 是否显示当前时间和持续时间的分隔符，"/"
          durationDisplay: false, // 是否显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  async mounted() {
    //如果为体验课 去掉是否购课校验 判断视频是否获取成功
    await this.getSource1();
    this.aiGameList(); // 游戏列表
  },

  methods: {
    getSource1() {
      this.api.unit
        .getPlayInfo({ videoId: this.videoid, definition: "" })
        .then((res) => {
          console.log(res, "新视频");
          this.options.sources[0].src = res.data.playInfoList[3].playURL;
          this.duration = res.data.playInfoList[0].duration;
          this.options.poster = res.data.videoBase.coverURL;
          this.fullVideoURL = res.data.videoBase.coverURL;
        });
    },
    bindToRoute() {
      if (this.gameId != "") {
        this.isChallenge = false;
        axios
          .get("http://127.0.0.1:8888/api", {
            params: {
              gameId: this.gameId,
              gradeId: parseInt(this.$route.query.gradeId),
              school: this.$store.state.userInfo.schoolId,
            },
          })
          .then((res) => {
            console.log(res);
            //获取你需要用到的数据
          });
      } else {
        this.$notice({
          type: "error",
          message: "请选择游戏",
        }).isShow();
      }

      //window.location.href = "https://www.prefootball.cn/?ai=test";
    },
    //  播放
    onPlayerPlay() {
      this.getTime();
      this.addStartClassroom();
      this.addNewClassRecord();
    },
    // 暂停回调
    onPlayerPause($event) {
      this.clear();
      console.log(this.timer, "播放时间111111111111111111111111111111111111");
      console.log("player pause!", $event);
    },
    // 视频播完回调
    onPlayerEnded($event) {
      console.log($event);
      this.addStartClassroom();
      this.addNewClassRecord();
      this.alertShow = true;
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting($event) {
      console.log($event, "11111111111111111111111111");
    },
    // 已开始播放回调
    onPlayerPlaying($event) {
      console.log($event, "222222222222222222222222");
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata($event) {
      console.log($event, "3333333333333333");
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate() {
      //console.log(this.onlineLearnedTime);
      //console.log($event, "4444444444444444");
    },
    // 媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay($event) {
      console.log("555555555555555555", $event);
    },
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough($event) {
      console.log("666666666666666666", $event);
    },
    //播放状态改变回调
    playerStateChanged() {
      // console.log(Math.round($event.timeupdate * 10) / 10);
      // if (this.$route.query.gradeType == "小班课程") {
      //   if (
      //     Math.round($event.timeupdate * 10) / 10 == 1200 ||
      //     Math.round($event.timeupdate * 10) / 10 == 1200.1
      //   ) {
      //     //1681.23
      //     this.$store.dispatch("checkAuth", {
      //       id: 38,
      //       fn: this.toAttend,
      //       that: this,
      //       fail: () => {},
      //     });
      //   }
      // } else if (
      //   this.$route.query.gradeType == "中班课程" ||
      //   this.$route.query.gradeType == "大班课程"
      // ) {
      //   if (Math.round($event.timeupdate * 10) / 10 == 1500) {
      //     //1681.23
      //     this.$store.dispatch("checkAuth", {
      //       id: 38,
      //       fn: this.toAttend,
      //       fail: () => {},
      //       that: this,
      //     });
      //   }
      // }
    },
    toAttend() {
      this.$refs.videoPlayer.player.pause();
      this.isChallenge = true;
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      console.log(player);
      // 改变播放时间
      //player.currentTime("8.933137");
      console.log("example player 1 readied");
    },

    showApp() {
      window.location.href = "h64";
    },
    aiGameList() {
      let id = this.$route.query.courseId;
      this.api.zhSchool.aiGameList(id).then((res) => {
        console.log(res, "111111111");
        this.gameList = res.data.data;
      });
    },
    addNewClassRecord() {
      this.api.unit
        .addNewClassRecord({
          gradeId: parseInt(this.$route.query.gradeId),
          studentIds: this.$route.query.studentIds,
          teacherId: this.$route.query.teacherId,
          userId: this.$store.state.userInfo.userId,
          videoId: parseInt(this.$route.query.videoId),
          classTime: parseInt(this.duration - 200),
          evaluatestatus: "0",
          classstatus: "1",
        })
        .then((res) => {
          console.log(res);
        });
    },

    addStartClassroom() {
      let NewClassroomOnline = {
        courseId: parseInt(this.$route.query.courseId),
        gradeId: parseInt(this.$route.query.gradeId),
        maxWatchTime: this.onlineLearnedTime,
        semesterId: this.$store.state.userInfo.semesterId,
        studentIds: this.$route.query.studentIds,
        teacherIds: this.$route.query.teacherId,
        vedioURL: this.$route.query.unnormalUrl,
        videoTotalTime: parseInt(this.duration),
        videoId: parseInt(this.$route.query.videoId),
        fullVideoURL: this.fullVideoURL,
      };
      this.api.school
        .addStartClassroom(
          this.$store.state.userInfo.schoolId,
          NewClassroomOnline
        )
        .then((res) => {
          console.log(res, "1111111111111111111111111111111111111111");
        });
    },

    getTime() {
      //to do
      this.times = setInterval(() => {
        this.onlineLearnedTime++;
        this.timerEnd = (this.onlineLearnedTime / this.duration) * 100;
        // console.log(end.toFixed(0));
      }, 1000);
    },
    //清除定时器
    clear() {
      if (this.times) {
        clearInterval(this.times);
      }
    },
    toEvaluate() {
      this.$router.push({
        path: "/evaluate",
        query: {
          id: this.evaluate,
          videoId: this.$route.query.videoId,
          gradeId: this.$route.query.gradeId,
        },
      });
    },
    bindSelectActive(id) {
      this.gameId = id;
    },
    // 关闭挑战接口
    bindClose() {
      this.isChallenge = false;
      this.$refs.videoPlayer.player.play();
    },
  },
  deactivated() {
    if (this.times) {
      clearInterval(this.times);
      this.onlineLearnedTime = 0;
    }
  },
  watch: {
    videoid: {
      handler(val) {
        console.log(val);
        this.videoid = val;
        this.getSource1();
      },
      deep: true, //true 深度监听
    },
    timerEnd: {
      handler(val) {
        if (val.toFixed(1) == 25) {
          this.addStartClassroom();
        } else if (val.toFixed(2) == 50) {
          this.addStartClassroom();
        } else if (val.toFixed(3) == 75) {
          this.addStartClassroom();
        }
      },
      deep: true, //true 深度监听
    },
  },
};
</script>

<style scoped lang="scss">
.my_video {
  width: 100%;
  height: 100%;
  position: relative;
}
/deep/ .video-player {
  width: 100%;
  height: 100%;
}
.alert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  @include flex();
  .pop-common {
    border-radius: 0.15rem !important;
    border: solid 10px #cc1a30;
    width: 18%;
    background: #fff;
    position: relative;
  }

  .sharePop .url {
    color: #cc1a30;
    font-size: 0.24rem;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 0.2rem;
  }

  .pop-common .layui-layer-content {
    padding: 0.4rem 0.45rem;
    font-size: 0.2rem;
    line-height: 0.35rem;
  }

  .sharePop {
    text-align: center;
  }

  .sharePop .img {
    margin-bottom: 0.2rem;
  }

  .sharePop .act {
    height: 0.1rem;
    line-height: 0.1rem;
    background: #cc1a30;
    color: #fff;
    display: inline-block;
    font-size: 0.24rem;
    font-weight: bold;
    padding: 0.2rem 0.4rem;
    margin: 0px 0.2rem;
    cursor: pointer;
  }

  .layui-layer-close2 {
    position: absolute;
    right: -20px;
    top: -20px;
    width: 36px;
    height: 32px;
    margin-left: 0;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    // background-position: -146px -32px; /*no*/
    @include bg("../assets/img/close.png");
  }
  /* 弹窗 */
  .pop-promt.layui-layer {
    border: solid 0.1rem #cb1b31;
    width: 6.8rem !important;
    height: 5.6rem !important;
    @include trans-center();
  }

  .pop-promt .promt {
    padding-top: 2.5rem;
    text-align: center;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn0 {
    background: #f5d1d6 !important;
  }

  .pop-promt .layui-layer-btn .layui-layer-btn1 {
    background: #cb1b31 !important;
  }

  .pop-promt .layui-layer-content {
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #cb1b31;
    overflow: hidden;
  }

  .pop-promt .sucess {
    background: url(../assets/img/sucess.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .warn {
    background: url(../assets/img/warn.png) center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .fail {
    background: url("../assets/img/fail.png") center 0.75rem no-repeat;
    background-size: auto 1rem;
  }

  .pop-promt .layui-layer-btn {
    text-align: center;
    display: flex;
    padding: 0px 0.7rem 0.4rem 0.7rem;
    position: absolute;
    width: 100%;
    bottom: 0.4rem;
    box-sizing: border-box;
  }

  .pop-promt .layui-layer-btn a {
    height: 0.7rem;
    font-size: 0.28rem;
    color: #fff;
    border: none;
    line-height: 0.7rem;
    border-radius: 0.5rem;
    flex: 1;
    margin: 0 15px;
    text-align: center;
    font-weight: 400;
    padding: 0;
  }

  .promt .tips {
    font-size: 0.32rem;
    line-height: 0.4rem;
  }

  .promt .desc {
    font-size: 0.24rem;
    line-height: 0.4rem;
  }

  .layui-layer {
    font-size: 0.18rem;
  }

  .iframe {
  }

  .iframe html {
    font-size: 100px;
  }

  .layui-layer-resize {
    display: none;
  }
}
.challenge {
  width: 1120px;
  height: 666px;
  background: #00000050;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .challenge-content {
    width: 831px;
    height: 563px;
    background: url(../assets/img/zhSchool/popup/Pic_Bg_Tc_GSTZ.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    .close_btn {
      position: absolute;
      top: 38px;
      right: 18px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .challenge-content-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      padding-top: 16px;
    }
    .challenge-content-list {
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .challenge-content-item {
        text-align: center;
        .challenge-content-item-img-active {
          width: 215px;
          height: 134px;
          background: url(../assets/img/zhSchool/popup/Pic_GSTZ_BK_C.png)
            no-repeat !important;
          background-size: 100% 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        .challenge-content-item-img {
          cursor: pointer;
          width: 215px;
          height: 134px;
          background: url(../assets/img/zhSchool/popup/Pic_GSTZ_BK_U.png)
            no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          video {
            width: 192px;
            height: 110px;
            opacity: 0.4;
            border-radius: 6px;
          }
          video::-webkit-media-controls {
            display: none !important;
          }
          img {
            width: 48px;
            height: 46px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
        .challenge-content-item-name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
        }
      }
    }
  }
  .challenge-content-rule {
    padding: 0 60px;
    margin-top: 40px;
    ul {
      li {
        display: flex;
        .bt {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c3e8ff;
          line-height: 28px;
          opacity: 0.6;
        }
        .num {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c3e8ff;
          line-height: 28px;
          flex: 1;
          margin-left: 10px;
        }
      }
    }
  }
  .challenge-content-btn {
    display: flex;
    justify-content: center;
    margin-top: 13px;
    .challenge-content-btn-que {
      width: 160px;
      height: 66px;
      background: url(../assets/img/zhSchool/popup/Btn_Bg.png) no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 66px;
      cursor: pointer;
    }
    .challenge-content-btn-cance {
      width: 160px;
      height: 66px;
      background: url(../assets/img/zhSchool/popup/Btn_Bg_canne.png) no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c3e8ff;
      text-align: center;
      line-height: 66px;
      cursor: pointer;
    }
  }
}
</style>