<template>
  <div class="head">
    <div class="head-title">
      <h2>{{ title }}</h2>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.head {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .head-title {
    width: 261px;
    height: 48px;
    font-size: 22px;
    line-height: 48px;
    font-weight: bold;
    color: #a4e2fc;
    background: url(../../../assets/img/datav/class_title_bg.png) no-repeat 100%
      100%;
  }
  h2 {
    padding-left: 15px;
  }
  span {
    font-size: 22px;
    font-weight: bold;
    color: #ffa000;
  }
}
</style>
